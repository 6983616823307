import React, { useEffect, useMemo } from 'react'
import { z } from 'zod'
import { Call as TwilioCall } from '@twilio/voice-sdk'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { map } from 'lodash'
import { Dropdown, FieldMessage, PrimaryButton, TextArea, TextField } from '@leadrilla/pulsar'
import { useStates } from '../../../hooks/queries/states'
import { useLeadStatuses } from '../../../hooks/queries/leadStatuses'
import { useVoip, useVoipActions } from '../../../hooks/voip'
import { useNotification } from '../../../hooks/notification'
import backend from '../../../backend'
import { OK } from '../../../constants/error_codes'
import { useFetchUserLeadByPhone } from '../../../hooks/queries/useFetchUserLeadByPhone'
import { getLeadBirthdate } from '../../../helpers/voip'
import { useStopwatch } from 'react-timer-hook'

const createFormSchema = ({ outcomeRequired }: { outcomeRequired: boolean }) =>
  z.object({
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    email: z.string().optional(),
    birthdate: z
      .string()
      .regex(/\d{4}-\d{2}-\d{2}/, 'Invalid date.')
      .or(z.literal('')),
    street_address: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    zip: z.string().optional(),
    notes: z.string().optional(),
    outcome: outcomeRequired ? z.string() : z.string().optional(),
  })

const VoipInboundCallDetailsWindow = ({ inboundCall }: { inboundCall: TwilioCall }) => {
  const { campaign } = useVoip()
  const { clearInboundCall } = useVoipActions()

  const callStatus = inboundCall.status()

  const { totalSeconds } = useStopwatch({ autoStart: true })

  useEffect(() => {
    if (callStatus === TwilioCall.State.Closed) {
      if (campaign && totalSeconds <= 60) {
        backend
          .post('/calls/connection-status', {
            caller_number: inboundCall.parameters.From,
            campaign_id: campaign.id,
          })
          .then(({ body }) => {
            if (!body.connected) clearInboundCall()
          })
      }
    }
  }, [callStatus])

  const { data: userLead } = useFetchUserLeadByPhone({
    phoneNumber: campaign && totalSeconds < 5 ? '' : inboundCall.parameters.From,
  })

  const { data: states } = useStates()
  const stateOptions = useMemo(
    () => states?.map(({ name, abbreviation }) => ({ text: name, value: abbreviation })) ?? [],
    [states]
  )

  const { data: leadStatuses } = useLeadStatuses(campaign?.product_id)
  const outcomeOptions = useMemo(
    () =>
      map(leadStatuses, ({ name }, key) => ({ text: name, value: key }))?.filter(
        ({ value }) => value !== 'NEW'
      ) ?? [],
    [leadStatuses]
  )

  const formSchema = createFormSchema({ outcomeRequired: !!campaign })

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: userLead?.first_name ?? '',
      last_name: userLead?.last_name ?? '',
      email: userLead?.email ?? '',
      birthdate: userLead ? getLeadBirthdate({ userLead }) : '',
      street_address: userLead?.street_address ?? '',
      city: userLead?.city ?? '',
      state: userLead?.state ?? '',
      zip: userLead?.zip ?? '',
    },
  })

  useEffect(() => {
    if (userLead) {
      reset({
        first_name: userLead.first_name ?? '',
        last_name: userLead.last_name ?? '',
        email: userLead.email ?? '',
        birthdate: getLeadBirthdate({ userLead }),
        street_address: userLead.street_address ?? '',
        city: userLead.city ?? '',
        state: userLead.state ?? '',
        zip: userLead.zip ?? '',
      })
    }
  }, [userLead, reset])

  const sendNotification = useNotification()

  const submitData = async (data: z.infer<typeof formSchema>) => {
    if (!userLead?.lead_id) {
      // @ts-expect-error FIXME
      sendNotification({ type: 'error' })
    } else if (userLead?.lead_id) {
      const { status } = await backend.put(`/leads/${userLead.lead_id}`, {
        ...data,
        resume_campaign_id: campaign?.id,
      })

      if (status !== OK) {
        // @ts-expect-error FIXME
        sendNotification({ type: 'error' })
      }
    }

    clearInboundCall()
  }

  return (
    <div className="flex h-full gap-[24px] p-[32px]">
      <div className="flex w-1/2 flex-col gap-[24px]">
        <div className="flex gap-[24px]">
          <TextField label="First Name" {...register('first_name')} />
          <TextField label="Last Name" {...register('last_name')} />
        </div>
        <TextField label="Street Address" {...register('street_address')} />
        <TextField label="City" {...register('city')} />
        <div className="z-50 flex gap-[24px]">
          <Controller
            name="state"
            control={control}
            render={({ field }) => {
              const { ref, ...props } = field
              return (
                <Dropdown
                  label="State"
                  placeholder="Select a state"
                  options={stateOptions}
                  optionsMaxHeight="150px"
                  {...props}
                />
              )
            }}
          />
          <TextField label="Postal Code" {...register('zip')} />
        </div>
      </div>
      <div className="flex w-1/2 flex-col gap-[24px]">
        <div className="flex flex-row gap-[24px]">
          <TextField className="w-1/2" label="Email Address" {...register('email')} />
          <TextField
            className="w-1/2"
            type="date"
            label="Date of Birth"
            tone={errors.birthdate && 'negative'}
            message={errors.birthdate?.message}
            {...register('birthdate')}
          />
        </div>
        <div>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => {
              // @ts-ignore
              return <TextArea className="h-[109px]" rows={4} label="Notes" {...field} />
            }}
          />
        </div>
        <Controller
          name="outcome"
          control={control}
          render={({ field }) => {
            const { ref, ...props } = field
            return (
              <div>
                <Dropdown
                  label="Outcome"
                  secondary={campaign ? 'Required' : ''}
                  placeholder="Select an outcome"
                  options={outcomeOptions}
                  optionsMaxHeight="150px"
                  {...props}
                />
                {errors.outcome && (
                  <div className="mt-[8px]">
                    <FieldMessage tone="negative">Please select an outcome.</FieldMessage>
                  </div>
                )}
              </div>
            )
          }}
        />
        <PrimaryButton
          fullWidth
          disabled={inboundCall.status() !== TwilioCall.State.Closed}
          onClick={handleSubmit(submitData)}
        >
          Save Call Notes
        </PrimaryButton>
      </div>
    </div>
  )
}

export default VoipInboundCallDetailsWindow
